import React, { createContext, useState, useEffect } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [userId, setUserId] = useState(null);
  const [userName, setUserName] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('token') || sessionStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = JSON.parse(atob(token.split('.')[1]));
        setIsAuthenticated(true);
        setUserRole(decodedToken.role);
        setUserId(decodedToken.id);
        setUserName(decodedToken.username);
      } catch (error) {
        console.error('Erreur lors du décodage du token:', error);
        logout(); // Déconnexion si le token est invalide
      }
    }
  }, []);

  const login = (token) => {
    localStorage.setItem('token', token);
    try {
      const decodedToken = JSON.parse(atob(token.split('.')[1]));
      setIsAuthenticated(true);
      setUserRole(decodedToken.role);
      setUserId(decodedToken.id);
      setUserName(decodedToken.username);
    } catch (error) {
      console.error('Erreur lors du décodage du token:', error);
      logout(); // Déconnexion si le token est invalide
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');
    setIsAuthenticated(false);
    setUserRole('');
    setUserId(null);
    setUserName('');
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout, userRole, userId, userName }}>
      {children}
    </AuthContext.Provider>
  );
};
