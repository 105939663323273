import React, { useContext, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { NotificationContext } from '../context/NotificationContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

function Header() {
  const { isAuthenticated, logout, userRole } = useContext(AuthContext);
  const { unreadCount } = useContext(NotificationContext);
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const NavLinks = ({ isMobile }) => (
    <>
      {!isAuthenticated ? (
        <>
          <Link to="/login" className={`px-3 py-2 rounded hover:bg-red-700 ${isMobile ? 'block' : ''}`}>Connexion</Link>
          <Link to="/register" className={`px-3 py-2 rounded hover:bg-red-700 ${isMobile ? 'block' : ''}`}>Inscription</Link>
        </>
      ) : (
        <>
          <Link to="/" className={`px-3 py-2 rounded hover:bg-red-700 ${isMobile ? 'block' : ''}`}>Tableau de bord</Link>
          <Link to="/badgeuse" className={`px-3 py-2 rounded hover:bg-red-700 ${isMobile ? 'block' : ''}`}>Badgeuse</Link>
          {userRole === 'admin' && (
            <Link to="/admin" className={`px-3 py-2 rounded hover:bg-red-700 ${isMobile ? 'block' : ''}`}>Administration</Link>
          )}
          <Link to="/notifications" className={`px-3 py-2 rounded hover:bg-red-700 relative ${isMobile ? 'block' : ''}`}>
            <span className="relative inline-block">
              <FontAwesomeIcon icon={faBell} />
              {unreadCount > 0 && (
                <span className="absolute -top-2 -right-2 inline-flex items-center justify-center w-5 h-5 bg-blue-600 text-white text-xs font-bold rounded-full">
                  {unreadCount}
                </span>
              )}
            </span>
            {isMobile && <span className="ml-2">Notifications</span>}
          </Link>
          <button 
            onClick={handleLogout} 
            className={`px-3 py-2 rounded bg-red-600 hover:bg-red-700 ${
              isMobile ? 'block w-full mt-4' : 'ml-2'
            }`}
          >
            Déconnexion
          </button>
        </>
      )}
    </>
  );

  return (
    <header className="bg-red-800 text-white p-4">
      <div className="container mx-auto flex justify-between items-center">
        <div className="flex items-center">
          <span className="text-xl font-bold">K-Planning</span>
        </div>
        <nav className="hidden md:flex md:items-center">
          <NavLinks isMobile={false} />
        </nav>
        <button onClick={toggleMenu} className="md:hidden">
          <FontAwesomeIcon icon={isMenuOpen ? faTimes : faBars} />
        </button>
      </div>
      {isMenuOpen && (
        <nav className="md:hidden mt-4">
          <NavLinks isMobile={true} />
        </nav>
      )}
    </header>
  );
}

export default Header;