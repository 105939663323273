import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';


const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;



const Dashboard = () => {
  const { userId, userName } = useContext(AuthContext); // Utilisez le contexte pour obtenir l'ID de l'utilisateur
  const [weekOffset, setWeekOffset] = useState(0);
  const [events, setEvents] = useState([]);
  const [showAddEventModal, setShowAddEventModal] = useState(false);
  const [newEvent, setNewEvent] = useState({
    type: '',
    start: '',
    end: '',
    pauseTime: 0
  });
  useEffect(() => {
    fetchEvents();
  }, [weekOffset]);

  const openAddEventModal = (date) => {
    setNewEvent({
      type: '',
      start: `${date}T09:00`,
      end: `${date}T17:00`,
      pauseTime: 60
    });
    setShowAddEventModal(true);
  };

  const isToday = (date) => {
    const today = new Date();
    return date === today.toISOString().split('T')[0];
  };

  const removeEvent = async (eventId) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cet événement ?')) {
      try {
        const response = await axios.post(`${apiBaseUrl}/api/supprimer_evenement`, { event_id: eventId });
        if (response.data.success) {
          setEvents(events.filter(event => event.id !== eventId));
        } else {
          alert('Erreur lors de la suppression de l\'événement : ' + response.data.message);
        }
      } catch (error) {
        console.error('Erreur lors de la suppression de l\'événement :', error);
        alert('Une erreur est survenue lors de la suppression de l\'événement.');
      }
    }
  };

  const getWeekDays = (offset = 0) => {
    const days = ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'];
    const today = new Date();
    today.setDate(today.getDate() + offset * 7);
    const currentDay = today.getDay();
    const diff = currentDay === 0 ? 6 : currentDay - 1;

    return days.map((day, index) => {
      const date = new Date(today);
      date.setDate(date.getDate() - diff + index);
      return {
        name: day,
        date: date.toISOString().split('T')[0]
      };
    });
  };

  const weekDays = getWeekDays(weekOffset);

  const currentMonth = new Date(weekDays[0].date).getMonth();
  const currentYear = new Date(weekDays[0].date).getFullYear();

  const navigateWeek = (direction) => {
    setWeekOffset(weekOffset + direction);
  };

  const currentWeekDisplay = () => {
    const startDate = weekDays[0].date;
    const endDate = weekDays[6].date;
    return `Semaine du ${formatDate(startDate)} au ${formatDate(endDate)}`;
  };

  const fetchEvents = async () => {
    try {
      const response = await axios.post(`${apiBaseUrl}/api/recuperer_planning`, { user_id: userId });
      console.log('Fetched Events Response:', response.data); // Debugging line
      if (response.data.success) {
        setEvents(response.data.events);
      } else {
        console.error('Erreur lors de la récupération des événements:', response.data.message);
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des événements:', error);
    }
  };

  const calculateDuration = (start, end, pauseTime) => {
    let startTime = new Date(`1970-01-01T${start}`);
    let endTime = new Date(`1970-01-01T${end}`);

    if (endTime < startTime) {
      endTime.setDate(endTime.getDate() + 1);
    }

    let duration = (endTime - startTime) / (1000 * 60); // durée en minutes
    duration -= parseInt(pauseTime.split(':')[0]) * 60 + parseInt(pauseTime.split(':')[1]);

    return Math.max(duration / 60, 0); // retourne la durée en heures
  };

  const weeklyHours = events
    .filter(event => event.event_date >= weekDays[0].date && event.event_date <= weekDays[6].date)
    .reduce((total, event) => total + calculateDuration(event.start_time, event.end_time, event.pause_time), 0);

    const getFirstDayOfMonth = (year, month) => {
      return new Date(year, month, 1).toISOString().split('T')[0];
    };
    
    const getLastDayOfMonth = (year, month) => {
      return new Date(year, month + 1, 0).toISOString().split('T')[0];  // Le jour 0 du mois suivant est le dernier jour du mois actuel
    };
    
    const firstDayOfMonth = getFirstDayOfMonth(currentYear, currentMonth);
    const lastDayOfMonth = getLastDayOfMonth(currentYear, currentMonth);
    
    const monthlyHours = events
      .filter(event => event.event_date >= firstDayOfMonth && event.event_date <= lastDayOfMonth)
      .reduce((total, event) => total + calculateDuration(event.start_time, event.end_time, event.pause_time), 0);
    
  const monthName = [
    'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
    'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
  ][currentMonth];

  const getEventsForDay = (date) => events.filter(event => event.event_date === date).sort((a, b) => new Date(`1970-01-01T${a.start_time}`) - new Date(`1970-01-01T${b.start_time}`));

  const formatTime = (time) => new Date(`1970-01-01T${time}`).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

  const formatPauseTime = (time) => `${parseInt(time.split(':')[0])}h${time.split(':')[1]}`;

  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split('-');
    return `${day}/${month}/${year}`;
  };

  const addEvent = async () => {
    try {
      const response = await axios.post(`${apiBaseUrl}/api/enregistrer_planning`, {
        user_id: userId,
        events: [{
          type: newEvent.type,
          start: newEvent.start,
          end: newEvent.end,
          pauseTime: newEvent.pauseTime
        }]
      });
      console.log('Add Event Response:', response.data); // Debugging line
      if (response.data.success) {
        alert('Événement ajouté avec succès !');
        setShowAddEventModal(false);
        setNewEvent({ type: '', start: '', end: '', pauseTime: '' });
        fetchEvents();
      } else {
        alert('Erreur lors de l\'ajout de l\'événement : ' + response.data.message);
      }
    } catch (error) {
      console.error('Erreur lors de l\'ajout de l\'événement :', error);
      alert('Une erreur est survenue lors de l\'ajout de l\'événement.');
    }
  };
  
  
// JSX for rendering the component
return (
 <div className="container mx-auto p-4">
   <h1 className="text-2xl md:text-3xl font-bold mb-6 text-center text-red-900">K-Planning</h1>

  <div className="mb-4 text-center">
        <h2 className="text-lg font-semibold text-red-900">Bienvenue, {userName} !</h2>
  </div>


   {/* Navigation arrows */}
   <div className="flex justify-between items-center mb-4">
     <button onClick={() => navigateWeek(-1)} className="text-red-900 hover:text-red-700 transition-colors duration-300">
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 md:h-6 md:w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
      </svg>
     </button>
     <span className="text-sm md:text-lg font-semibold text-red-900">{currentWeekDisplay()}</span>
     <button onClick={() => navigateWeek(1)} className="text-red-900 hover:text-red-700 transition-colors duration-300">
      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 md:h-6 md:w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
      </svg>

     </button>
   </div>

   {/* Summary of hours */}
   <div className="bg-red-100 p-4 rounded-lg mb-4 flex justify-between items-center">
     <div>
       <h2 className="text-lg font-semibold text-red-900">Heures cette semaine</h2>
       <p className="text-2xl font-bold text-red-700">{weeklyHours.toFixed(2)} h</p>
     </div>
     <div>
       <h2 className="text-lg font-semibold text-red-900">Heures en {monthName} {currentYear}</h2>
       <p className="text-2xl font-bold text-red-700">{monthlyHours.toFixed(2)} h</p>
     </div>
   </div>

   {/* Planning grid */}
   <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-7 gap-4">
     {weekDays.map(day => (
       <div key={day.date} 
            className={`bg-white rounded-lg shadow-sm overflow-hidden ${isToday(day.date) ? 'ring-2 ring-red-500' : ''}`}>
         <div onClick={() => openAddEventModal(day.date)} 
              className={`p-2 text-center cursor-pointer transition-colors duration-300 ${isToday(day.date) ? 'bg-red-200 hover:bg-red-300' : 'bg-red-100 hover:bg-red-200'}`}>
           <div className="font-bold text-base md:text-lg text-red-900">{day.name}</div>
           <div className="text-xs md:text-sm text-gray-600">{formatDate(day.date)}</div>
         </div>
         <div className="p-2 space-y-2">
           {getEventsForDay(day.date).map(event => (
             <div key={event.id} 
                  className={`p-2 rounded relative ${isToday(day.date) ? 'bg-red-100' : 'bg-red-50'}`}>
               <div className="font-semibold text-sm md:text-base text-red-900">{event.type}</div>
               <div className="text-xs md:text-sm text-gray-600">
                 {formatTime(event.start_time)} - {formatTime(event.end_time)}
               </div>
               {event.pause_time !== '00:00:00' && (
                 <div className="text-xs text-gray-500">
                   Pause: {formatPauseTime(event.pause_time)}
                 </div>
               )}
               <button onClick={() => removeEvent(event.id)} className="absolute top-1 right-1 text-red-600 hover:text-red-800">
                 <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                   <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                 </svg>
               </button>
             </div>
           ))}
           {getEventsForDay(day.date).length === 0 && (
             <div className="text-sm text-gray-500 italic">Aucun événement</div>
           )}
         </div>
       </div>
     ))}
   </div>

   {/* Add Event Modal */}
   {showAddEventModal && (
     <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
       <div className="bg-white p-4 md:p-6 rounded-lg w-full max-w-md">
         <h2 className="text-xl md:text-2xl font-bold mb-4 text-red-900">Ajouter un événement</h2>
         <form onSubmit={addEvent}>
           <div className="mb-4">
             <label htmlFor="type" className="block text-gray-700 text-sm font-bold mb-2">
               Type d'événement
             </label>
             <input
               value={newEvent.type}
               onChange={(e) => setNewEvent({ ...newEvent, type: e.target.value })}
               className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
               id="type"
               type="text"
               placeholder="Type d'événement"
             />
           </div>
           <div className="mb-4">
             <label htmlFor="start" className="block text-gray-700 text-sm font-bold mb-2">
               Heure de début
             </label>
             <input
               value={newEvent.start}
               onChange={(e) => setNewEvent({ ...newEvent, start: e.target.value })}
               className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
               id="start"
               type="datetime-local"
             />
           </div>
           <div className="mb-4">
             <label htmlFor="end" className="block text-gray-700 text-sm font-bold mb-2">
               Heure de fin
             </label>
             <input
               value={newEvent.end}
               onChange={(e) => setNewEvent({ ...newEvent, end: e.target.value })}
               className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
               id="end"
               type="datetime-local"
             />
           </div>
           <div className="mb-4">
             <label htmlFor="pauseTime" className="block text-gray-700 text-sm font-bold mb-2">
               Temps de pause (minutes)
             </label>
             <input
               value={newEvent.pauseTime}
               onChange={(e) => setNewEvent({ ...newEvent, pauseTime: e.target.value })}
               className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
               id="pauseTime"
               type="number"
               min={0}
             />
           </div>
           <div className="flex items-center justify-between">
             <button
               type="submit"
               className="bg-red-900 hover:bg-red-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
             >
               Ajouter
             </button>
             <button
               type="button"
               onClick={() => setShowAddEventModal(false)}
               className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
             >
               Annuler
             </button>
           </div>
         </form>
       </div>
     </div>
   )}
 </div>
);
};

export default Dashboard;