import React, { useState } from 'react';
import UserManagement from './UserManagement'; // Import the UserManagement component
import PlanningManagement from './PlanningManagement'; // Import the PlanningManagement component
import BroadcastNotification from './BroadcastNotification'; // Import the BroadcastNotification component

function Admin() {
  const [selectedSection, setSelectedSection] = useState('users'); // Default section

  const renderSection = () => {
    switch (selectedSection) {
      case 'users':
        return <UserManagement />;
      case 'planning':
        return <PlanningManagement />;
      case 'broadcast':
        return <BroadcastNotification />;
      default:
        return <UserManagement />;
    }
  };

  return (
    <div className="flex flex-col lg:flex-row h-screen">
      {/* Sidebar for navigation */}
      <div className="bg-red-800 text-white w-full lg:w-64 p-4 shadow-lg">
        <h2 className="text-lg font-bold text-center mb-6">Administration</h2>
        <ul className="space-y-2">
          {['users', 'planning', 'broadcast'].map((section) => (
            <li key={section}>
              <button
                onClick={() => setSelectedSection(section)}
                className={`block w-full text-left px-4 py-2 rounded transition duration-200 
                  ${selectedSection === section ? 'bg-red-700' : 'hover:bg-red-900'}`}
              >
                {section === 'users' ? 'Gestion des Utilisateurs' : section === 'planning' ? 'Gestion des Plannings' : 'Envoyer une Notification'}
              </button>
            </li>
          ))}
        </ul>
      </div>

      {/* Main content area */}
      <div className="flex-grow p-6 bg-gray-100 overflow-auto">
        <h2 className="text-2xl font-bold mb-6">Administration</h2>
        {renderSection()}
      </div>
    </div>
  );
}

export default Admin;
