import React, { useState, useEffect } from 'react';
import axios from 'axios';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

function Badgeuse() {
  const [status, setStatus] = useState('');
  const [entries, setEntries] = useState([]);

  const fetchTodayEntries = async () => {
    try {
      const token = localStorage.getItem('token') || sessionStorage.getItem('token');
      const response = await axios.get(`${apiBaseUrl}/api/today-entries`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setEntries(response.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des entrées/sorties', error);
    }
  };

  useEffect(() => {
    fetchTodayEntries();
  }, []);

  const handleCheckIn = async () => {
    try {
      const token = localStorage.getItem('token') || sessionStorage.getItem('token');
      const response = await axios.post(`${apiBaseUrl}/api/check-in`, {}, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 201) {
        const time = new Date().toLocaleTimeString();
        setStatus(`Entrée validée à ${time}`);
        fetchTodayEntries();
      }
    } catch (error) {
      setStatus("Erreur lors de la validation de l'entrée");
      console.error(error);
    }
  };

  const handleCheckOut = async () => {
    try {
      const token = localStorage.getItem('token') || sessionStorage.getItem('token');
      const response = await axios.post(`${apiBaseUrl}/api/check-out`, {}, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 200) {
        const time = new Date().toLocaleTimeString();
        setStatus(`Sortie validée à ${time}`);
        fetchTodayEntries();
      }
    } catch (error) {
      setStatus("Erreur lors de la validation de la sortie");
      console.error(error);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-xl font-bold mb-4">Badgeuse</h2>
      <button onClick={handleCheckIn} className="bg-green-500 text-white px-4 py-2 rounded mr-2">
        Badger Entrée
      </button>
      <button onClick={handleCheckOut} className="bg-red-500 text-white px-4 py-2 rounded">
        Badger Sortie
      </button>
      {status && <p className="mt-4">{status}</p>}
      
      <h3 className="text-lg font-semibold mt-6">Entrées/Sorties du Jour</h3>
      <table className="min-w-full bg-white mt-2">
        <thead>
          <tr>
            <th className="py-2">Heure d'Entrée</th>
            <th className="py-2">Heure de Sortie</th>
          </tr>
        </thead>
        <tbody>
          {entries.map((entry, index) => (
            <tr key={index}>
              <td className="border px-4 py-2">{entry.check_in ? new Date(entry.check_in).toLocaleTimeString() : 'Aucune'}</td>
              <td className="border px-4 py-2">{entry.check_out ? new Date(entry.check_out).toLocaleTimeString() : 'Aucune'}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Badgeuse;
