import React, { useState, useEffect } from 'react';
import axios from 'axios';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

function UserManagement() {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [userPlanning, setUserPlanning] = useState([]);
  const [weekOffset, setWeekOffset] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');
        const response = await axios.get(`${apiBaseUrl}/api/admin/users`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUsers(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des utilisateurs', error);
      }
    };

    fetchUsers();
  }, []);

  const fetchUserDetailsAndPlanning = async (userId) => {
    try {
      const token = localStorage.getItem('token') || sessionStorage.getItem('token');

      const userDetailsResponse = await axios.get(`${apiBaseUrl}/api/users/${userId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUserDetails(userDetailsResponse.data);

      const userPlanningResponse = await axios.get(`${apiBaseUrl}/api/users/${userId}/planning`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUserPlanning(Array.isArray(userPlanningResponse.data) ? userPlanningResponse.data : []);
    } catch (error) {
      console.error("Erreur lors de la récupération des détails ou du planning de l'utilisateur", error);
    }
  };

  const handleViewDetails = (user) => {
    setSelectedUser(user);
    fetchUserDetailsAndPlanning(user.id);
    setIsModalOpen(true);
  };

  const getWeekDays = (offset = 0) => {
    const days = ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'];
    const today = new Date();
    today.setDate(today.getDate() + offset * 7);
    const currentDay = today.getDay();
    const diff = currentDay === 0 ? 6 : currentDay - 1;

    return days.map((day, index) => {
      const date = new Date(today);
      date.setDate(date.getDate() - diff + index);
      return {
        name: day,
        date: date.toISOString().split('T')[0],
      };
    });
  };

  const weekDays = getWeekDays(weekOffset);

  const getEventsForDay = (date) => userPlanning.filter(event => event.event_date === date);

  const calculateWeeklyHours = () => {
    if (!Array.isArray(userPlanning)) {
      return 0;
    }

    return userPlanning.reduce((total, event) => {
      const duration = calculateDuration(event.start_time, event.end_time);
      return total + duration;
    }, 0);
  };

  const calculateDuration = (start, end) => {
    const startTime = new Date(`1970-01-01T${start}`);
    const endTime = new Date(`1970-01-01T${end}`);
    return (endTime - startTime) / (1000 * 60 * 60);
  };

  const weeklyHours = calculateWeeklyHours();

  const navigateWeek = (direction) => {
    setWeekOffset(weekOffset + direction);
  };

  return (
    <div className="p-4 sm:p-8 lg:p-12">
      <h3 className="text-lg font-semibold mb-4">Gestion des Utilisateurs</h3>
      <table className="min-w-full bg-white mb-4 border border-gray-300 rounded-lg shadow">
        <thead>
          <tr className="bg-gray-200">
            <th className="py-2">ID</th>
            <th className="py-2">Nom d'utilisateur</th>
            <th className="py-2">Email</th>
            <th className="py-2">Rôle</th>
            <th className="py-2">Détails</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user.id} className="hover:bg-gray-100 transition duration-200">
              <td className="border px-4 py-2">{user.id}</td>
              <td className="border px-4 py-2">{user.username}</td>
              <td className="border px-4 py-2">{user.email}</td>
              <td className="border px-4 py-2">{user.role}</td>
              <td className="border px-4 py-2">
                <button 
                  className="text-blue-500 hover:text-blue-700"
                  onClick={() => handleViewDetails(user)}
                >
                  👁 Voir
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isModalOpen && selectedUser && (
        <div className="bg-white p-6 rounded-lg shadow-md max-w-md mx-auto mb-8">
          <h3 className="text-lg font-semibold mb-4">Planning de {selectedUser.username}</h3>
          <div className="mb-4 flex justify-between items-center">
            <button onClick={() => navigateWeek(-1)} className="text-red-900 hover:text-red-700">Précédent</button>
            <span className="font-semibold">Semaine {weekOffset + 1}</span>
            <button onClick={() => navigateWeek(1)} className="text-red-900 hover:text-red-700">Suivant</button>
          </div>

          <div className="bg-red-100 p-4 rounded-lg mb-4">
            <h2 className="text-lg font-semibold text-red-900">Heures cette semaine</h2>
            <p className="text-2xl font-bold text-red-700">{weeklyHours.toFixed(2)} h</p>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-7 gap-4">
            {weekDays.map(day => (
              <div key={day.date} className="bg-white rounded-lg shadow-sm overflow-hidden">
                <div className="p-2 text-center">
                  <div className="font-bold text-base text-red-900">{day.name}</div>
                  <div className="text-xs text-gray-600">{day.date}</div>
                </div>
                <div className="p-2 space-y-2">
                  {getEventsForDay(day.date).map(event => (
                    <div key={event.id} className="p-2 bg-red-100 rounded relative">
                      <div className="font-semibold text-sm text-red-900">{event.type}</div>
                      <div className="text-xs text-gray-600">
                        {event.start_time} - {event.end_time}
                      </div>
                    </div>
                  ))}
                  {getEventsForDay(day.date).length === 0 && (
                    <div className="text-sm text-gray-500 italic">Aucun événement</div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {isModalOpen && selectedUser && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-8 rounded-lg shadow-md max-w-md w-full">
            <h3 className="text-lg font-semibold mb-4">Détails de {selectedUser.username}</h3>
            {userDetails ? (
              <div>
                <p><strong>Email :</strong> {userDetails.email}</p>
                <p><strong>Rôle :</strong> {userDetails.role}</p>
                <p><strong>Heures Hebdomadaires :</strong> {userDetails.weekly_hours}</p>
                <p><strong>Heures Mensuelles :</strong> {userDetails.monthly_hours}</p>
              </div>
            ) : (
              <p>Chargement des détails...</p>
            )}
            <button
              onClick={() => setIsModalOpen(false)}
              className="mt-4 py-2 px-4 bg-red-500 text-white rounded-lg"
            >
              Fermer
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default UserManagement;
