import React, { useState, useEffect } from 'react';
import axios from 'axios';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

function BroadcastNotification() {
  const [message, setMessage] = useState('');
  const [userId, setUserId] = useState('');
  const [status, setStatus] = useState('');
  const [users, setUsers] = useState([]);

  useEffect(() => {
    // Récupérer la liste des utilisateurs (accessible aux admins)
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');
        const response = await axios.get(`${apiBaseUrl}/api/admin/users`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setUsers(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des utilisateurs", error);
      }
    };

    fetchUsers();
  }, []);

  const sendNotification = async () => {
    try {
      const token = localStorage.getItem('token') || sessionStorage.getItem('token');
      const endpoint = userId ? `${apiBaseUrl}/api/notifications/send` : `${apiBaseUrl}/api/notifications/broadcast`;
      const data = userId ? { userId, message } : { message };

      await axios.post(endpoint, data, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setStatus('Notification envoyée avec succès');
      setMessage('');
      setUserId('');
    } catch (error) {
      setStatus("Erreur lors de l'envoi de la notification");
      console.error(error);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h2 className="text-xl font-bold mb-4">Envoyer une Notification</h2>
      <textarea
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        placeholder="Entrez votre message ici..."
        className="w-full p-2 border rounded mb-4"
      />
      
      {/* Sélection de l'utilisateur */}
      <select
        value={userId}
        onChange={(e) => setUserId(e.target.value)}
        className="w-full p-2 border rounded mb-4"
      >
        <option value="">Sélectionnez un utilisateur (ou laissez vide pour tous)</option>
        {users.map(user => (
          <option key={user.id} value={user.id}>
            {user.username} ({user.email})
          </option>
        ))}
      </select>

      <button onClick={sendNotification} className="bg-blue-600 text-white px-4 py-2 rounded">
        Envoyer
      </button>
      {status && <p className="mt-4">{status}</p>}
    </div>
  );
}

export default BroadcastNotification;
