import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

const PlanningManagement = () => {
  const { userId, userName } = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const [userPlanning, setUserPlanning] = useState({});
  const [weekOffset, setWeekOffset] = useState(0);

  useEffect(() => {
    const fetchUsers = async () => {
      const token = localStorage.getItem('token') || sessionStorage.getItem('token');
      const response = await axios.get(`${apiBaseUrl}/api/admin/users`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setUsers(response.data);
    };
    fetchUsers();
  }, []);

  const fetchUserPlanning = async (userId) => {
    const token = localStorage.getItem('token') || sessionStorage.getItem('token');
    const userPlanningResponse = await axios.get(`${apiBaseUrl}/api/users/${userId}/planning`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    return userPlanningResponse.data.events || [];
  };

  const loadAllUserPlannings = async () => {
    const planningData = {};
    for (const user of users) {
      planningData[user.id] = await fetchUserPlanning(user.id);
    }
    setUserPlanning(planningData);
  };

  useEffect(() => {
    if (users.length > 0) {
      loadAllUserPlannings();
    }
  }, [users]);

  const getWeekDays = (offset = 0) => {
    const days = ['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'];
    const today = new Date();
    today.setDate(today.getDate() + offset * 7);
    const currentDay = today.getDay();
    const diff = currentDay === 0 ? 6 : currentDay - 1;

    return days.map((day, index) => {
      const date = new Date(today);
      date.setDate(today.getDate() - diff + index);
      return {
        name: day,
        date: date.toISOString().split('T')[0],
        displayDate: `${day} ${date.getDate()}/${String(date.getMonth() + 1).padStart(2, '0')}`,
      };
    });
  };

  const getMonthName = (monthNumber) => {
    const monthNames = [
      "Janvier", "Février", "Mars", "Avril", "Mai", "Juin", 
      "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"
    ];
    return monthNames[monthNumber - 1];
  };

  const weekDays = getWeekDays(weekOffset);
  const monthNumber = parseInt(weekDays[0].date.split('-')[1]);
  const monthName = getMonthName(monthNumber);

  const getEventsForDay = (userId, date) => userPlanning[userId]?.filter(event => event.event_date === date) || [];
  const calculateTotalHours = (events) => {
    if (!events || !Array.isArray(events)) return 0;

    const weekStart = new Date(weekDays[0].date);
    const weekEnd = new Date(weekDays[weekDays.length - 1].date);
    weekEnd.setHours(23, 59, 59, 999); // Inclure toute la journée de fin de semaine

    return events.reduce((total, event) => {
      const eventDate = new Date(event.event_date);

      // Vérifier si l'événement est dans la semaine actuelle
      if (eventDate >= weekStart && eventDate <= weekEnd) {
        const start = new Date(`1970-01-01T${event.start_time}:00`);
        const end = new Date(`1970-01-01T${event.end_time}:00`);

        if (end > start) {
          const duration = (end - start) / (1000 * 60 * 60);
          const pauseHours = getPauseHours(event.pause_time);
          return total + duration - pauseHours;
        }
      }
      return total;
    }, 0).toFixed(2);
  };

  const getPauseHours = (pauseTime) => {
    if (!pauseTime) return 0;
    const [hours, minutes, seconds] = pauseTime.split(':').map(Number);
    return hours + minutes / 60 + seconds / 3600;
  };

  const navigateWeek = (direction) => {
    setWeekOffset(weekOffset + direction);
  };

  return (
    <div className="container mx-auto p-4">
      <h1 className="text-2xl md:text-3xl font-bold mb-6 text-center text-red-900">K-Planning</h1>
      <div className="mb-4 text-center">
        <h2 className="text-lg font-semibold text-red-900">Gestion des Plannings</h2>
      </div>

      <div className="flex justify-between items-center mb-4">
        <button onClick={() => navigateWeek(-1)} className="text-red-900 hover:text-red-700 transition-colors duration-300">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 md:h-6 md:w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
          </svg>
        </button>
        <span className="text-sm md:text-lg font-semibold text-red-900">Semaine {weekOffset + 1}</span>
        <button onClick={() => navigateWeek(1)} className="text-red-900 hover:text-red-700 transition-colors duration-300">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 md:h-6 md:w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
          </svg>
        </button>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full bg-white rounded-lg shadow-md">
          <thead>
            <tr className="bg-red-100 text-red-900">
              <th className="p-4 text-left">Utilisateur</th>
              {weekDays.map(day => (
                <th key={day.date} className="p-4">{day.displayDate}</th>
              ))}
            </tr>
            <tr>
              <th colSpan={weekDays.length + 1} className="p-4 text-center text-red-900 font-semibold">
                Mois de {monthName}
              </th>
            </tr>
          </thead>
          <tbody>
            {users.map(user => (
              <React.Fragment key={user.id}>
                <tr className="border-b hover:bg-red-50">
                  <td className="p-4 font-semibold text-red-900">
                    {user.username} <span className="text-black">{calculateTotalHours(userPlanning[user.id])} h</span>
                  </td>
                  {weekDays.map(day => (
                    <td key={day.date} className="p-4">
                      {getEventsForDay(user.id, day.date).length > 0 ? (
                        getEventsForDay(user.id, day.date).map(event => (
                          <div key={event.id} className="text-sm text-gray-800">
                            <div className="font-semibold">{event.type}</div>
                            <div className="text-xs">{event.start_time} - {event.end_time}</div>
                          </div>
                        ))
                      ) : (
                        <div className="text-sm text-gray-500 italic">Aucun événement</div>
                      )}
                    </td>
                  ))}
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PlanningManagement;
