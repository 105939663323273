import React, { useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import './App.css';
import './styles/tailwind.css';
import Header from './components/Header';
import Login from './components/Login';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import Badgeuse from './components/Badgeuse'; // Import the Badgeuse component
import Admin from './components/Admin'; // Import Admin component
import Notifications from './components/Notifications'; // Import the Notifications component

import { AuthProvider, AuthContext } from './context/AuthContext';

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <Header />
          <Routes>
            <Route path="/" element={<ProtectedRoute component={Dashboard} />} />
            <Route path="/badgeuse" element={<ProtectedRoute component={Badgeuse} />} />
            <Route path="/admin" element={<AdminRoute component={Admin} />} /> {/* Add Admin route */}
            <Route path="/login" element={<PublicRoute component={Login} />} />
            <Route path="/register" element={<PublicRoute component={Register} />} />
            <Route path="/notifications" element={<ProtectedRoute component={Notifications} />} />
          </Routes>
        </div>
      </Router>
    </AuthProvider>
  );
}

const ProtectedRoute = ({ component: Component }) => {
  const { isAuthenticated } = useContext(AuthContext);
  return isAuthenticated ? <Component /> : <Navigate to="/login" />;
};

const AdminRoute = ({ component: Component }) => {
  const { isAuthenticated, userRole } = useContext(AuthContext);
  return isAuthenticated && userRole === 'admin' ? <Component /> : <Navigate to="/" />;
};

const PublicRoute = ({ component: Component }) => {
  const { isAuthenticated } = useContext(AuthContext);
  return !isAuthenticated ? <Component /> : <Navigate to="/" />;
};

export default App;